import { defineStore } from 'pinia'
import type { Workflow, WorkflowStep } from '~/repository/modules'
import { WorkflowStepType } from '~/repository/modules'

export const useWorkflowStore = defineStore('workflow', () => {
  const { $api } = useNuxtApp()

  const workflow = ref<Workflow>()
  const workflowLocalChannel = new BroadcastChannel('workflow-updates')
  const loading = ref<boolean>(false)

  const get = async () => {
    loading.value = true
    const { data } = await $api.workflow.get()
    workflow.value = {
      ...data,
      steps: data.steps.sort((a, b) => a.step - b.step),
    }
    $api.workflow.$events.updated.trigger(undefined)

    loading.value = false
  }

  const handleWorkflowUpdate = (notification: any) => {
    switch (notification.name) {
      case 'workflow.updated':
        get()
        break
    }
  }

  workflowLocalChannel.onmessage = (event) => {
    handleWorkflowUpdate(event.data.data)
  }

  const workflowStepExists = async (stepId: string) => {
    if (loading.value) {
      await new Promise((resolve) => {
        watchOnce(loading, () => {
          resolve(undefined)
        })
      })
    }

    if (!workflow.value)
      return false

    return useFind(workflow.value.steps, (step: WorkflowStep) => {
      return step.id === stepId
    }) !== undefined
  }

  const getWorkflowStep = (stepId: string): WorkflowStep | false => {
    if (!workflow.value || !workflowStepExists(stepId))
      return false

    return useFind(workflow.value.steps, (step: WorkflowStep) => step.id === stepId) as WorkflowStep
  }

  const workflowSteps = computed(() => {
    return workflow.value?.steps || []
  })

  const getWorkflowStepByType = (stepType: string): WorkflowStep | false => {
    if (!workflow.value)
      return false

    return useFind(workflow.value.steps, (step: WorkflowStep) => step.name === stepType) as WorkflowStep
  }

  $api.cases.on(['moved', 'touched', 'moveFailed'], () => {
    get()
  })
  $api.cases.adjudication.on('created', () => get())

  const configurableSteps = [
    WorkflowStepType.Sent,
    WorkflowStepType.DODCheck,
  ]

  const multiProcessWorkflowSteps = computed(() => {
    const sortedSteps = (workflowSteps.value || []).slice().sort((a, b) => a.step - b.step)
    const stepNameByNumber = new Map(sortedSteps.map(step => [step.step, step.name]))
    return sortedSteps.map((step, index) => {
      const prevStepNumber = step.step - 1
      const prevStepName = stepNameByNumber.get(prevStepNumber) || null
      return {
        ...step,
        prev_step_name: prevStepName,
      }
    }).filter(step => configurableSteps.includes(step.name))
  })

  const multiProcessStepNames = computed(() => {
    return multiProcessWorkflowSteps.value
      .filter(step => step.requires_processing)
      .map(step => step.prev_step_name)
  })

  return {
    workflow,
    handleWorkflowUpdate,
    workflowLocalChannel,
    loading,
    get,
    workflowStepExists,
    getWorkflowStep,
    getWorkflowStepByType,
    workflowSteps,
    multiProcessWorkflowSteps,
    multiProcessStepNames,
  }
})
